<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="questionnaire" service="mortgage" :backlink="backlink" />
      <div class="content illustration illustration_plane">
        <div class="content__container">
          <form @submit.prevent="submitHandler">
            <p class="promo promo_blocks">{{ "s4p19_text_1" | localize }}</p>
            <v-radio-group
              v-model="contract"
              :class="{ invalid: $v.contract.$dirty && !$v.contract.required }"
              ref="contract"
              row
              @change="$v.contract.$touch(); checkForm(false);"
            >
              <v-radio
                :label="'yes' | localize"
                value="1"
                on-icon="icon-radio-on"
                off-icon="icon-radio-off"
              ></v-radio>
              <v-radio
                :label="'no' | localize"
                value="0"
                on-icon="icon-radio-on"
                off-icon="icon-radio-off"
              ></v-radio>
            </v-radio-group>

            <p class="question">{{ "s4p19_text_2" | localize }}</p>
            <div class="form__group">
              <v-textarea
                solo
                auto-grow
                v-model="text"
                @input="checkForm($v.text)"
                :class="{ invalid: $v.text.$dirty && !$v.text.required }"
                :placeholder="'s4p19_placeholder_1' | localize"
              ></v-textarea>
            </div>

            <button class="button button__form" :class="{ button__form_disabled: !success }">
              {{ "continue" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import Backlink from '@/components/base/Backlink.vue'
import { commonMixin } from '@/mixins/commonMixin.js'
import { saveMixin } from '@/mixins/saveMixin.js'

export default {
  name: 'Mortgage_payment',
  components: { Backlink },
  mixins: [commonMixin, saveMixin],
  data: () => ({
    backlink: '/',
    contract: null,
    text: null,
    success: false
  }),
  created () {
    const mortgage = this.$cookies.get('mortgage') || {}
    this.backlink = mortgage.has_delay === 1 ? '/mortgage/about-delay' : '/mortgage/delay'
    this.contract = mortgage.contract || null
    this.text = mortgage.text || null
  },
  validations: {
    contract: { required },
    text: { required }
  },
  methods: {
    checkForm () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.success = false
      } else {
        this.success = true
      }
    },
    submitHandler () {
      if (this.$v.$invalid) {
        this.$v.text.$touch()
        this.success = false
        return
      }

      const mortgage = this.$cookies.get('mortgage') || {}
      mortgage.contract = this.contract
      mortgage.text = this.text
      this.$cookies.set('mortgage', mortgage)
      this.saveServiceInfo('mortgage', '/mortgage/payment', { text: this.text })
    }
  }
}
</script>
